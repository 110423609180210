/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired


				$('.banner').affix({
				  offset: 100
				});

				$('.navbar-toggle, .disabled a, .show-multi-site-nav, a[href=#global-network]').click( function (e) {
					e.preventDefault();
				});


				


				$.contactButtons({
					effect  : '', // 'slide-on-scroll',
					buttons : {
						'facebook':   { class: 'facebook', 	use: useFB, link: ptmFB, extras: 'target="_blank" data-label="Like Us on Facebook"' },
						'twitter':    { class: 'twitter', 	use: useTwitter, link: ptmTwitter, extras: 'target="_blank" data-label="Follow Us on Twitter"' },
						'instagram':  { class: 'instagram', use: useInstagram, link: ptmInstagram, icon: 'instagram', extras: 'data-label="Instagram"' },
						'youtube':    { class: 'youtube', 	use: useYoutube, link: ptmYoutube, icon: 'youtube', extras: 'data-label="Our YouTube Channel"' }
					}
				});







				


				$('.slick-carousel').slick({
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				});


				$('.banner-carousel').slick({
					autoplay: true,
					arrows: false,
					adaptiveHeight: true
				});


				$('.overlaid-carousel').slick({
					autoplay: false,
					arrows: false,
					dots: true,
					adaptiveHeight: true
				});


				$('.product-gallery').slick({
					autoplay: true,
					arrows: false,
					dots: true,
					adaptiveHeight: true
				});


				$('.PI_SimpleTwitterTweets .tweets').slick({
					slide: 'li',
					adaptiveHeight: true
				});


				$('.PI_SimpleTwitterTweets .tweets .stt-em').each( function() {
					$(this).prev('a').addClass('tweet-link');
				});


				$('.tweets').slick({
					autoplay: true,
					arrows: false,
					dots: true,
					adaptiveHeight: true
				});


				$('.tweets .stt-em').each( function() {
					$(this).prev('a').addClass('tweet-link');
				});





				// History Timeline slider synchronisation
				$('.history-timeline-nav')
					.on('init', function(event, slick) {
						$('.slick-current').prev().addClass('prev-slide');
        		$('.slick-current').next().addClass('next-slide');
					})
					.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
						$('.slick-slide').removeClass('prev-slide next-slide');
					})
					.on('afterChange', function(event, slick, currentSlide, nextSlide) {
						$('.slick-current').prev().addClass('prev-slide');
        		$('.slick-current').next().addClass('next-slide');
					});

				$('.history-timeline-nav').slick({
					infinite: false,
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: true,
					dots: false,
					centerMode: true,
					centerPadding: '0',
					focusOnSelect: true,
					asNavFor: '.history-timeline-content'
				});

				$('.history-timeline-content').slick({
					infinite: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					appendArrows: '.history-timeline-content-arrows',
					arrows: true,
					prevArrow: '<div class="col-xs-6 align-right"><button type="button" class="btn btn-default">What Happened Before?</button></div>',
					nextArrow: '<div class="col-xs-6"><button type="button" class="btn btn-primary">What Happened Next?</button></div>',
					dots: false,
					focusOnSelect: true,
					asNavFor: '.history-timeline-nav'
				});









				var videos_urls = [];

				$(".embedded-video").each( function() {
					videos_urls[$(this).attr('id')] = $(this).attr('src');
				});
				
				
				/* Assign empty url value to the iframe src attribute when
				modal hide, which stop the video playing */
				$(".video-modal").on('hide.bs.modal', function() {
					$(this).find('.embedded-video').attr('src', '');
				});
				
				/* Assign the initially stored url back to the iframe src
				attribute when modal is displayed again */
				$(".video-modal").on('show.bs.modal', function() {
					var videoSRC = $(this).find('.embedded-video').attr('id');
					$(this).find('.embedded-video').attr('src', videos_urls[videoSRC] + '?autoplay=1');
				});






				// Real 3D Flip Book trigger
				var $viewPackBtn = '<span class="btn btn-primary"><i class="fa fa-angle-right"></i> Click to View Pack</span>';

				$(document).on('ready', function() {

					if ( $('[class^=real3dflipbook]').length > 0 ) {
						$('[class^=real3dflipbook]').append($viewPackBtn);
						$('[class^=real3dflipbook] img').hide();
					}
				});



			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
